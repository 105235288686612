.Head{
  display: flex;
  float: left;
  justify-content: center;
}

.BackHeadIcon{
  display: none;
}

.invisible-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  color: transparent;
}



.BackHeadNavigator-1{
  display: none;
}
.BackHeadNavigator-3{
  display: none;
}
.BackHeadNavigator-4{
  display: none;
}
.BackHeadNavigator-5{
  display: none;
}
.BackHeadIcon-1{
  display: none;
}
.BackHeadIcon-2{
  display: none;
}
.BackHeadIcon-3{
  display: none;
}



.ImoHeadbox {
  position: relative;
  margin-left: 0px; 
  display: flex;
  align-items: center;
}


.ImoHeadNavigator-1{
  display: none;
}
.ImoHeadNavigator-2{
  display: none;

}
.ImoHeadNavigator-3{
  display: none;

}
.ImoHeadNavigator-4{
  margin-right: 0px;

}
.ImoHeadNavigator-5{
  display: none;
}
.ImoHeadIcon-1{
  display: none;
}
.ImoHeadIcon-2{
  margin-left: 0px;
}

.ImoHeadIcon-2 div{
margin-top: -3px;
}
.ImoHeadIcon-3{
  display: none;
}
.ImoHeadbox{
  position: relative;
  margin-left: 0px; 
  display: flex;
  align-items: center; /* 추가 */

}
.ImoHeadmargin1{
  margin-left: 40px;
}
.ImoHeadmargin2{
  margin-right: 0px;
}
  













.ImoHeadWhitebox{
  display: flex;
  justify-content: center;
  height: auto;
}
.ImoHeadWhiteNavigator-1{
  display: none;
}
.ImoHeadWhiteNavigator-2{
  display: none;
}
.ImoHeadWhiteNavigator-3{
  display: none;
}
.ImoHeadWhiteNavigator-4{
  display: none;
}

.ImoHeadWhiteIcon-1{
  display: none;
}
.ImoHeadWhiteIcon-2{
  display: none;
}

.ImoHeadWhiteIcon-3{
  margin-right: 210px;
}





.MainHeadNavigator-2{
  display: none;
  margin-left: -10px;

}
.MainHeadNavigator-4{
  display: none;
}
.MainHeadNavigator-5{
  display: none;
}
.MainHeadIcon-2{
  display: none;
}
.MainHeadIcon-3{
  display: none;
}
.MainHeadbox{
  position: relative;
  margin-left: 0px; 
  display: flex;
  justify-content: center;
}
.MainHeadmargin1{
  margin-right: 250px;
}
.MainHeadmargin2{
  margin-right: 100px;
}

.MainHead {
  margin-top:'150px';
}




.LandingNavigator-1{
  display: none;

}

.LandingNavigator-3{
  display: none;

}
.LandingNavigator-2{
  display: none;
  margin-left: -10px;

}
.LandingNavigator-4{
  display: none;
}
.LandingNavigator-5{
  display: none;
}
.LandingIcon-2{
  display: none;
}
.LandingIcon-3{
  display: none;
}
.Landingbox{
  position: relative;
  margin-left: 0px; 
  display: flex;
  justify-content: center;
}
.Landingmargin1{
  margin-right: 110px;
}
.Landingmargin2{
  margin-right: 110px;
}

.Landing {
  margin-top:'150spx';
}




.XconNavigator-1{
  display: none;
}
.XconNavigator-2{
  display: none;
}
.XconNavigator-3{
  display: none;
}
.XconNavigator-4{
  display: block;
}
.XconNavigator-5{
  display: none;
}
.XconIcon-1{
  display: none;
}
.XconIcon-2{
  display: none;
}
.XconIcon-3{
  display: none;
}
.Xconbox{
  position: relative;
  margin-left: 0px; 
  display: flex;
  justify-content: center;
}
.Xconmargin1{
  margin-right: 96px;
}
.Xconmargin2{
  margin-right: 96px;
}