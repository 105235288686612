* {
  -webkit-tap-highlight-color:rgba(255,255,255,0);
  user-select: none;
  -webkit-touch-callout: none;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  touch-action: pan-y; 
}

body, html, .App, .main .main_content{
  overscroll-behavior: none  !important; 
}

.main {
  background-color: #ffffff;
  font-family: 'Pretendard';
  letter-spacing: -0.08rem;
  max-width: 840px;
  height: 100%;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch; /* 추가 */
}

.App {
  text-align: center;
  background-color: #ffffff;
  overflow-y: auto; /* 스크롤이 보이도록 설정 */
  
}


@media screen and (min-width: 840px) {
  .main_content {
    position: relative;
    max-width: 840px;
    min-height: 100vh;

  }

  .main_content:before {
    content: " ";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0;
    border-left: 1px solid;
    border-color: #d1d6db;
  }

  .main_content:after {
    content: " ";
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;
    border-right: 1px solid;
    border-color: #d1d6db;
  }

  div {
    display: block;
  }
}


.master-container-menu {
  width: 330px;
  height: 2.2%;
  margin-top: 30px;
  margin-left: 35px;
  background-color: #FF5C00;
  border-color: #FF5C00;
  border: none;
  display: inline-block;
  text-align: left;
  font-family: 'Pretendard';
  font-weight: 800;
}



.ad_container {
  display: flex;
  justify-content: flex-end;
  z-index: 90;

}

.coupang_ad {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 20px;
  z-index: 90;

}

.closeButton {
  width: 100px;
  border: none;
  font-family: 'Pretendard';
  font-weight: 700;
  background-color: #ffffff;
  color: #222222;
  cursor: pointer;
  margin-top: 5px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip {
  visibility: visible; /* 초기에 툴팁을 보이게 설정 */
  width: 115px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 12px;
  line-height: 16px;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: 130%; /* 꼬리와의 간격을 조절 */
  left: 50%;
  transform: translateX(-50%);
  opacity: 1; /* 초기에 툴팁을 보이게 설정 */

  animation: bounce 1s infinite alternate;
  transition: opacity 4s ease-in-out; /* 툴팁의 opacity 트랜지션 설정 */
}

.tooltip.fade-out {
  opacity: 0; /* 툴팁을 페이드 아웃시키기 위해 opacity 0으로 설정 */
}

@keyframes bounce {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(-5px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}



.cta-link {
  margin-right: 45.5%;
  color: #FF5C00;
  width: 330px;
  height: 2.6%;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 40px;
  text-align: left;
  letter-spacing: -0.408px;
  -webkit-text-stroke: 0.5px #FFFFFF;
  -moz-text-stroke: 0.5px #FFFFFF;
  transition: all 0.3s ease;
}

.cta-link:hover {
  -webkit-text-fill-color: #FFFFFF;
  -moz-text-fill-color: #FFFFFF;
  -webkit-text-stroke: none;
  -moz-text-stroke: none;
}

.vote_box {
  width: 340px;
  height: 60px;
  border-radius: 10px;
  background: #FBF5F5;
  display: inline;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 2px solid #FF402F;
  border-image: none; /* border-image 속성 해제 */
}




.content {
  font-family: system-ui, serif;
  font-size: 2rem;
  padding: 3rem;
  border-radius: 1rem;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 3500ms;
}

.NickName {
  font-family: 'Pretendard';
  background: transparent;
  border-radius: 6px;
  width: 300px;
  height: 40px;
  font-size: 40px;
  text-align: center;
  text-indent: 10px;
  border: none;
  outline: none;
}

.NickName::placeholder {
  color: #c9c9c9;
}

.BottomWrap {
  margin-top: 0px;
}

@media (min-width: 330px) and (max-width: 384px) {
  .BottomWrap {
    margin-top: 90px;
  }
}

.ButtonBox {
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  background: linear-gradient(to bottom, transparent, #D9D9D9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
}



.Boxsquare {
  margin-bottom: 50px;
}

.MessageTo {
  text-align: left;
  margin-left: 25px;
  font-size: 15px;
  font-family: Pretendard;
  font-weight: 500;
}






a {
  text-decoration: none;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hoxyworld_icon {
  margin-top: 30px; 
  margin-right: 25px; /* 이미지를 오른쪽으로 15px 띄움 */
}

.header_right {
  margin-right: 25px; /* 이미지를 오른쪽으로 15px 띄움 */
}

.hoxyoption_icon {
  margin-top: 35px; 
  margin-left: 40px; /* 이미지를 오른쪽으로 15px 띄움 */
}

.top_bar {
  display: flex;
}

.top_icon {
  margin-top: 32px; 
  margin-left: 15px;
  display: flex;
  color: #191F28;
}

.LinkBox {
  font-family: 'Pretendard';
  font-weight: 500;
  background: #FBF6F6;
  border-radius: 10px;
  width: 340px;
  height: 40px;
  font-size: 17px;
  line-height: 40px;
  vertical-align: middle;
  justify-content: center;
  border: none;
  outline: none;
  text-align: center;
}

.primary {
  width: 340px;
  height: 50px;
  vertical-align: middle;
  border-radius: 60px;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  border: none;
  color: #ffffff;
  background: linear-gradient(#FF862E, #FF2E2E);
  font-family: 'Pretendard';
  text-align: center;
}

.request_img {
width: 20px;
height: 20px;
margin-left: 5px;
margin-top: -2px;

}

.select_request {
  margin-right: 0px;
  margin-left: 10px;
  background: #fff;
  width: 104px;
  height: 40px;
  border-color: #f0f0f0;
  border-radius: 60px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #acacac;
  font-family: 'Pretendard';
  letter-spacing: -0.08rem;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  border: 1px solid #acacac;
}

.select_request2 {
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  width: 160px;
  height: 40px;
  margin-top: 5px;
  border-color: #f0f0f0;
  border-radius: 60px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #acacac;
  font-family: 'Pretendard';
  letter-spacing: -0.08rem;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  border: 1px solid #acacac;
  
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.skeleton-box {
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite; /* Add the pulsating animation */
}

.skeleton-line {
  width: 150px;
  height: 20px;
  margin-top: 10px;
  background-color: #f0f0f0;
}

/* Pulsating animation */
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}


.input_request2 {
  background: #fff;
  width: 330px;
  height: 30px;
  border-color: #f0f0f0;
  border-radius: 60px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #000000;
  font-family: 'Pretendard';
  letter-spacing: -0.08rem;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  text-align: center;
  border: 1px solid #acacac;
}

.select_request.active {
  color: #000000;
  border: 2px solid #ff1500;
  background: #fff;
  border-color: #ff1500;
}

.select_request2.active {
  color: #000000;
  border: 2px solid #ff1500;
  background: #fff;
  border-color: #ff1500;
}

.input_request {
  margin-right: 25px;
  background: #fff;
  width: 160px;
  height: 35px;
  border-color: #f0f0f0;
  margin-left: 25px;
  border-radius: 60px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #000000;
  font-family: 'Pretendard';
  letter-spacing: -0.08rem;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  border: 1px solid #acacac;
}

.gradeButton {
  font-family: 'Pretendard';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 35px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #B9B9B9;
  border-radius: 18px;
  background-color: #FFFFFF;
  color: #B9B9B9;
  cursor: pointer;
  letter-spacing: -0.08rem;
}

.gradeButton.active {
  border-color: #FF5C00;
  color: #FF5C00;
}

option {
  background-color: #ffffff;
}

.SelectBox {
  font-family: 'Pretendard';
  font-weight: 600;
  letter-spacing: -0.08rem;
  background: #F3F3F3;
  border-radius: 15px;
  width: 320px;
  background: url('./stories/assets/select_box_icon.svg') no-repeat 95% 50%;
  height: 50px;
  font-size: 16px;
  border: none;
  outline: none;
  text-indent: 14px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.SelectBox::-ms-expand {
  display: none;
}

.NickNameinput::placeholder {
  color: #c9c9c9;
  font-family: 'Pretendard';
  font-weight: 600;
  letter-spacing: -0.08rem;
}
.Nameinput{
  font-family: 'Pretendard';
  font-weight: 600;
  letter-spacing: -0.08rem;  
  background: #F3F3F3;
  border-radius: 15px;
  width: 320px;
  height:50px;
  font-size: 16px;
  border: none;
  outline: none;
  text-indent: 14px;
  box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
color: #000000;
-webkit-appearance: none;
-moz-appearance: none; 
appearance: none;
}



/* Add this CSS to your existing App.css or create a new CSS file */

/* Styles for the popup container */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

/* Styles for the popup content */
.popup-content {
  max-width: 380px;
  background-color: #fff;
  padding: 5px 20px 15px 20px;
  text-align: left;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 99;

}

/* Styles for the NPS options */
.nps-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nps-option {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.nps-option.selected {
  background-color: #007bff;
  color: #fff;
  border: none;
}

/* Styles for the reason options */
.reason-option {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}

.reason-option.selected {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.button-container {
  font-family: 'Pretendard';
  font-weight: 800;
  letter-spacing: -0.08rem;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.fade-in {
  animation: fadeIn ease 1s;
}

.fade-out {
  animation: fadeOut ease 1s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}



.delete-button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #FFF5EC;
  color: #FF5C00;
  border: none;
  border-radius: 12px;
  font-size: 17px;
  cursor: pointer;
  font-family: 'Pretendard';
  font-weight: 700;
  letter-spacing: -0.08rem;
}

.earth-img {
  width: 165px;
  height: 165px;
  animation: float 4s ease-in-out infinite;
}


@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.retry-button {
  padding: 10px 20px;
  background-color: #FF5C00;
  color: #FFFFFF;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Pretendard';
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.08rem;
}

/* Optional: Add hover styles */
.delete-button:hover,
.retry-button:hover {
  opacity: 0.8;
}

.hint_box{
  width: 340px;
  height: 70px;
  background-color: #F2F3F5;
  border-radius: 15px;
}
.hint_button {
  border: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;

}

.hint_img {
  width: 30px;
  height: 30px;
  margin-top: 20px;
  background-color: transparent;
}

.hint_p1 {
  font-size: 13px;
  font-weight: 500;
  color: #8B95A1;
  margin-top: 18px;
}



.custom-reason-input {
  font-family: 'Pretendard';
  font-weight: 600;
  letter-spacing: -0.08rem;
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.shuffle-button {
  margin-top: 10px;
  background-color: transparent;
  color: #acacac;
  border: none;
  cursor: pointer;
  font-family: 'Pretendard';
  font-weight: 800;
  letter-spacing: -0.02rem;
  font-size: 18px;
}


.popup-container {
  /* Add styles for the semi-transparent background overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.custom-popup {
  /* Add styles for the popup content */
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pop-up-button {
  font-family: 'Pretendard';
  letter-spacing: '-0.08rem';
  background-color: #362697;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.pop-up-button:hover {
  /* Add styles for button hover effect */
  background-color: #281c56;
}


.shuffle-button:hover {
  color: #FF5C00; /* 더 밝은 주황색 계열 */
}

/* 버튼 활성화 시 스타일 */
.shuffle-button:active {
  color: #FF5C00; /* 더 진한 주황색 계열 */
}

/* styles.css */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

@keyframes blinking {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}



.welcome_btn_fill  {
  background: #FF5C00;
  border-radius: 15px;
  font-family: 'Pretendard';
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.08rem;
  text-align: center;
  width: 170px;
    height: 50px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    border: none;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.welcome_btn_nf  {
  border-color: #FF5C00;
  border-radius: 15px;
  font-family: 'Pretendard';
  border: 2px solid #FF5C00; /* border 속성 수정 */
  color: #FF5C00;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.08rem;
  text-align: center;
  width: 170px;
  height: 50px;
  text-align: center;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}


.flexbox{
  display: flex; 
  justify-content: center;
}

.welcome_box {
  display: flex;
  justify-content: center; /* Center the content both horizontally and vertically */
  align-items: center;
  text-align: center;
  font-style: normal;
  text-decoration: none;
  background-color: transparent;
  font-family: 'Pretendard';
  letter-spacing: -0.08rem;
}

/* Welcome.css */

/* Define the style for the "mini" version of the image */
.welcome_mini_image {
  max-width: 100%;
  height: auto;
  max-height: 220px; /* Adjust the max height for smaller screens */
}



.welcome_ButtonBox {
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
  margin-bottom: 10px;
}

.title {
  color: #191F28;
  font-size: 28px;
  font-weight: 800;
  margin-top: -30px;
  line-height: 38px;


}

.subtitle {
  font-family: 'Pretendard';
  color: #000000;
  font-size: 17px;
  font-weight: 500;
  letter-spacing:-0.08rem; 
}


/* Optional: Add focus styles for the input field */
.custom-reason-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}



.Ad_Box {
  width: 300px;
  height: 250px;
  display:block;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}



.SchoolNow{
  color: #191F28;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.44px;
}

.SchoolNowBox{
  background-color: inherit;
  display: flex;
  justify-content: center;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  background: #FF5C00;
  border-radius: 15px;
  color: #FFF;
  font-family: 'Pretendard';
  font-size:8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
  margin-top: 10px;
}

.SchoolNowBox2{
  background-color: inherit;
  display: flex;
  justify-content: center;
  width: 110px;
  height: 35px;
  flex-shrink: 0;
  background: #FEEEDF;
  border-radius: 15px;
  color: #FF5C00;
  font-family: Pretendard;
  font-size:8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
  margin-top: 10px;
}
.SchoolNowImo{
  background-color: inherit;

  margin-top: 4px;
}

.SchoolNowInfo{
  background-color: inherit;

  margin-top: 4px;
}

.SchoolRankBox{
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: #FBF6F6;
  width: 155px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 9px;
  border-style: solid;
  border: 2px solid #FF402F;
}

.SchoolRankBox{
  color: #191F28;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
}

.SchoolRankImo{
  margin-top: 11px;
}

.SchoolRankName{
  margin-top: 12px;
}


.SchoolRankRequest{
  margin-left: 3px;
  margin-top: 15px;
  color: #575353;
  font-family: Pretendard;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.56px;
}

.WorldMessage{
  margin-top: 35px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2px;
}

.WorldNum{
  text-align: center;
  font-family: Pretendard;
  font-size: 45px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -3.6px;

  background: linear-gradient(89deg, #2E60F5 0%, #8350F5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.WorldButtonBox{
  background-color: inherit;
  position: fixed;
  bottom: 0;
  width: 100%; /* Ensure it spans the full width */
  padding: 20px; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in width calculation */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.WorldButton{
  background-color: inherit;
  margin-top: 18px;

  width: 330px;
  height: 50px;
  flex-shrink: 0;

  border-radius: 60px;
  background: linear-gradient(83deg, #2E60F5 0%, #8350F5 100%);
}

.WorldButtonText{
  margin-top: 15px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.44px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:0px;
}

.GiftIGot{
  background-color: inherit;

  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.92px;
}

.GiftBox{
  margin-top: 15px;
  width: 350px;
  height: 160px;
  border-radius: 25px;
  background: #FBF5F5;
  }

.GiftBlock{
  background-color: inherit;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FEEEDF;
}

 .GiftText1{
  background-color: inherit;
  color: #6E747D;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.96px;
 }

 .GiftText2{
  background-color: inherit;
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
 }

 progress{
  background-color: inherit;

  width: 175px;
  height: 8px;
  flex-shrink: 0;
  
  border-radius: 60px;
  background: linear-gradient(90deg, #FF5C00 0%, #FF8A00 100%);
 }

 .MiniGiftBox{
  background-color: inherit;
  width: 349px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background: #FBF5F5;
 }

 .FIvoteText1{
  color: #000;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.96px;
  text-align: left;
 }

 .FIvoteText2{
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
  text-align: left;
 }

 .FIvoteBox{
  width: 351px;
  height: 60px;
  flex-shrink: 0;

  border-radius: 15px;
  background: #FBF6F6;
 }

 .FIvoteTitle{
  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.92px;
 }

 .FIvoteTitle2{
  margin-right: 170px;
  color: #000;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.36px;
 }

 .DetailButton{
  color: #FF5C00;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.36px;
 }

 .VoteOption{
  margin-top: 15px;

  width: 354px;
  height: 40px;
  flex-shrink: 0;

  border-radius: 10px;
  background: #FBF5F5;

  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.2px;
 }

 .FIvoteTitle3{
  margin-right: 100px;

  color: #000;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.36px;
 }

 .Unknown{
  margin-left: 15px;
  margin-right: auto;
  margin-top: 11px;
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.44px;
 }

 .MainVoteBox{
  margin-top: 10px;

  width: 340px;
  height: 60px;
  flex-shrink: 0;

  border-radius: 10px;
  background: #FBF5F5;
 }

 .ToWorldButton{
  width: 60px;
  height: 35px;
  flex-shrink: 0;

  border-radius: 35px;
  background: #FF5C00;
 }

 .ManInCircle {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin-left: 25px;
  border-radius: 50%; /* 변경된 테두리 반경 */
  border: 2px solid #0A98D3;
  background: url(../src/assets/img/boy.webp) center / cover no-repeat; /* 변경된 배경 설정 */
}

.WomanInCircle {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin-left: 25px;
  border-radius: 50%; /* 변경된 테두리 반경 */
  border: 2px solid #F2479E;
  background: url(../src/assets/img/young-girl.webp) center / cover no-repeat; /* 변경된 배경 설정 */
}

.LMBoxLine1{
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.LMBox1{
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px 25px 20px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: linear-gradient(79deg, #DD3B6B 0%, #E3973C 100%);
}

.LMBox2{
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px 25px 20px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: linear-gradient(99deg, #BBE2FF 0%, #D6EEFF 100%);
}

.LMBoxLine2{
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
}

.LMBox3{
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px 25px 20px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: linear-gradient(99deg, #FF8947 0%, #FFB053 100%);
}

.LMBox4{
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px 25px 20px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: linear-gradient(74deg, #80DE69 0%, #C0D791 100%);
}


.LMBoxLine3{
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 40px;
}

.LMBox5{
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px 25px 20px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;

  border-radius: 25px;
  background: linear-gradient(78deg, #FF7B7B 0%, #FFAE9C 100%);
}

.LMBox6{
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px 25px 20px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;

  border-radius: 25px;
  background: linear-gradient(83deg, #FF82D4 0%, #1400FF 100%);
}

.LMBoxText{
  width: 100px;
  height: 30px;
  flex-shrink: 0;

  border-radius: 15px;
  background: #FFF;

  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.88px;
}

.Dtitle{
  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.92px;
}

.fight_condition{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.36px;
}

.conditions{
  margin-top: 19px;


  color: #778694;
  text-align: center;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.04px;
}

.DbuttonBox{
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
}

.DbuttonText{
  margin-bottom: 12px;
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.2px;
}

.FriendBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin-top: 22px;
  margin-left: 17px;
  width: 150px;
  height: 150px;
  flex-shrink: 0;

  border-radius: 25px;
  background-color: #FBF5F5;

  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
}

.FriendBoxButton{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90px;
  height: 25px;
  flex-shrink: 0;

  border-radius: 5px;
  background: #FF5C00;

  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.88px;
}

.VisitNumBox{
  display: flex;
  justify-content: center;
  
  width: 110px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #FBF5F5;

  color: #000;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.69); /* 덮을 색상과 투명도 설정 */
  z-index: 99; /* main_contents 위에 위치하도록 z-index 조정 */
}

.MainButton_selected{
  display: flex;
  width: 200px;
  padding: 14px 119px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  background: rgba(255, 92, 0, 0.30);

  color: var(--Hoxy-Orange, #FF5C00);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.68px;
}

.MainButton_unselected{
  display: flex;
  width: 200px;
  padding: 14px 119px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  background: var(--fills-tertiary, rgba(118, 118, 128, 0.12));

  color: var(--labels-secondary, rgba(60, 60, 67, 0.60));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.68px;
}

.LinkButton{
  display: flex;
  width: 340px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  background: var(--Hoxy-Orange, #FF5C00);

  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.68px;
}

.HintTitle{
  width: 191px;
  height: 18px;
  flex-shrink: 0;

  color: #000;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.36px;
}

.HintBox{
  width: 340px;
  height: 70px;
  flex-shrink: 0;

  border-radius: 15px;
  background: #FBF5F5;
  margin-top: 9px;
}

.WhatText{

  color: #6E747D;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
}

.HintHowText{
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -1.12px;
}

.SugBox{
  width: 150px;
  height: 150px;
  flex-shrink: 0;

  border-radius: 15px;
  background: #FBF5F5;
}

.SugText{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.2px;
}

.ThemeTitle{
  color: #191F28;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.44px;

  margin-right: '63px';
}

.JokerBox{
  width: 180px;
  height: 250px;
  flex-shrink: 0;

  border-radius: 15px;
  background: #FBF5F5;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.RequestBox{
  width: 230px;
  height: 310px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FBF5F5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.SelecBox{
  width: 345px;
  height: 50px;
  flex-shrink: 0;
  text-align: left;
  border-radius: 12px;
  background: #F6F6F6;
}

.InstaSearchBox{
  width: 345px;
  height: 40px;
  flex-shrink: 0;

  border-radius: 12px;
  background: #F6F6F6;
}

.iftext{
  color: #6E747D;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 200% */
  letter-spacing: -0.44px;
  margin-top: 5px;
}

.search-input::placeholder {
  color: #D2D6DB;
}

.howtext{ 
  color: #000;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.64px;
  margin-top: -5px;
}


.Header_Joker{
  display: flex;
  flex-direction: column;


}

.Content-1_Joker{
  display: flex;
  justify-content: center;

  flex-direction: column;

  margin-top: 35px;


}



.middle_component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}



.middle_component_25 {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}

.middle_component_text {
  color: #000;
text-align: center;
font-family: 'Pretendard';
font-size: 20px;
font-weight: 700;
line-height: 22px; /* 110% */
letter-spacing: -0.8px;
width: 100%;
}



.Content-2_Joker{
  display: flex;
  justify-content: center;

  flex-direction: column;

  margin-top: 29px;
}




.JokerIntro1{
  color: #FF5C00;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 200% */
  letter-spacing: -0.44px;
}

.JokerIntro2{
  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 91.667% */
  letter-spacing: -0.96px;
}

.search_header{
  display: flex;
  justify-content: center;
}

.search-input{
  color : '#D2D6DB'
}

.recent_search{
  color: #000;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.36px;
}

.UserName{
  color: #000;
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.2px;
  margin-bottom: 10px;
}

.SchoolName{
  color: #848484;
  font-family: 'Pretendard';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.88px;
  margin-top: -12px;
}



.Circle{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: 45px;
}

.Name{
  font-family: 'Pretendard';
  font-weight: 600;
  letter-spacing: -0.09rem;
  font-size: 20px;
  line-height: 40px;
  /* identical to box height */
  margin-left: 10px;
  text-align: left;
  color: #000000;


}

.Xicon{
  margin-top: 10px;
  margin-right: 45px;
}

.NameBox {
  margin-top: 5px;

}

.Look{
  width: 72px;
  height: 18px;
  flex-shrink: 0;

  color: #FF5C00;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.36px;

  margin-left:65px;
}

.NoLook{
  display: none;
}

.CastingInputTitle{
  color: var(--Hoxy-Orange, #FF5C00);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 200% */
  letter-spacing: -0.44px;
}

.input-group{
  width: 344px;
  height: 150px;
  margin: auto;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0; 
}

.input-group-group{
  width: 344px;
  height: 50px;
  overflow: hidden;
  position: relative;
  border: none;

}

label {
  position: absolute;
  top: 20px; /* Replace $width/15 with a fixed value */
  color: #848484;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;

  border: none;

  cursor: text;
  transition: 0.25s ease;
}

input {
  display: block;
  width: 344px;
  padding-top: 20px;
  border: none;
  border-radius: 0;
  color: #000;
  background: #fff; /* Set a different background color */
  font-size: 20px;
}

input:valid ~ label {
  top: 0;
  font: 600 16px Pretendard; /* Replace $width/25 with a fixed value */
  color: #FF5C00;
  border: none;
}

input:focus ~ label {
  top: 0;
  font: 600 16px Pretendard; /* Replace $width/25 with a fixed value */
  color: #FF5C00;
  border: none; /* Replace $secondary-color with the actual color code */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; /* Replace $main-color with the actual color code */
  -webkit-text-fill-color: #848484 !important;
  border: none;

}

select {
  display: block;
  width: 344px;
  padding-top: 20px;
  border: none;
  border-radius: 0;
  color: #000;
  background: #fff; /* Set a different background color */
  font-size: 20px;
}

select:valid ~ label {
  top: 0;
  font: 600 16px Pretendard; /* Replace $width/25 with a fixed value */
  color: #FF5C00;
  border: none;
}

select:focus ~ label {
  top: 0;
  font: 600 16px Pretendard; /* Replace $width/25 with a fixed value */
  color: #FF5C00;
  border: none; /* Replace $secondary-color with the actual color code */
}

select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; /* Replace $main-color with the actual color code */
  -webkit-text-fill-color: #848484 !important;
  border: none;

}

.bar {
  background:  #848484;
  width: 344px; /* Replace $width with a fixed value */
  height: 1px; /* Adjust the height as needed */
  display: block;
  border: #000 1px;
  border-color: #000;
  
}



::selection {
  background: rgba(255, 255, 255, 0.3);
}

.CastingReasonBox{

  width:310px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  justify-content: center;
  border-radius: 12px;
  background: #EEEEEF;
}

input:focus {outline: none;}
textarea:focus {outline: none;}

.CastingReasonInput{
  align-self: stretch;
  font-family: 'Pretendard';
  color: #717C98;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 19.5px */
  letter-spacing: -0.6px;  
  text-decoration: none;
  border: none; 
  outline: none;
  resize: none; 
  padding: 10px;
  background : transparent
  
  }

  .CastingTitle {
    white-space: pre-line;
  }
  

.buttonStyle2 {
  display: flex;
  width: 344px;
  padding: 14px 119px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--fills-tertiary, rgba(118, 118, 128, 0.12));
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--labels-secondary, rgba(60, 60, 67, 0.60));

}

.buttonStyle1 {
  display: flex; 
  color: #FF5C00;
  width: 344px;
  padding: 14px 119px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FFEFDD;
  border: none;
  outline: none;
  cursor: pointer;
  color: #FF5C00

}

.buttonContainerStyle{
  position: fixed;
  bottom: 89px;
  left: 50%;
  transform: translateX(-50%);
}


.sexbuttonContainerStyle1{
  position: fixed;
  bottom: 52%;
  left: 50%;
  transform: translateX(-50%);
}

.sexbuttonContainerStyle2{
  position: fixed;
  bottom:44%;
  left: 50%;
  transform: translateX(-50%);
}



.buttonTextStyle{
    text-align: center;
    font-family: Pretendard;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.68px;
    white-space: nowrap; 
}

.onboarding_middle_text_1{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin-top: 60px;
}

.onboarding_middle_text_2{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-top: 5px;

}

.onboarding_middle_text_3{

  color: rgba(144, 145, 154, 0.80);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-top: 5px;
}

.MovingBox {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FBF5F5;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* 각 .MovingBox에 대해 animation-delay 값을 지정합니다. */
.MovingBox:nth-child(1) {
  animation-delay: 1s;
  overflow-x: hidden;
  overflow-y: hidden;
}

.MovingBox:nth-child(2) {
  animation-delay: 2s;
  overflow-x: hidden;
  overflow-y: hidden;
}

.MovingBox:nth-child(3) {
  animation-delay: 3s;
  overflow-x: hidden;
  overflow-y: hidden;
}

@keyframes boxDelay1 {
  0% {
    left: 100px;
  }
  100% {
    left: 1000px;
  }
}

@keyframes boxDelay2 {
  0% {
    right: 100px;
  }
  100% {
    right: 1000px;
  }
}

.Moving_right{
  display: flex;
  justify-content: center; 
  position: relative;
  animation: boxDelay2 10s linear infinite;
  width:500px;
  margin-left: 200px;
  overflow-x: hidden;
  overflow-y: hidden;
}


.Moving_Left{
  display: flex;
  justify-content: center; 
  position: relative;
  animation: boxDelay1 10s linear infinite;
  width:500px;
  margin-left: -330px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.profile_example{
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
  width: 223px;
  height: 367px;
  flex-shrink: 0;
  border-radius: 30px;
}

.open_coupang_font{
  color: #3C414B;
  text-align: center;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.04px;
}

.open_coupang_font_blur{
  color: rgba(144, 145, 154, 0.80);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.84px;

  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
}
.coupang_middle{
  position: fixed;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
}

.sexbuttonStyle1{
  width: 344px;
  height: 50px;
  flex-shrink: 0;
  
  background-color: #FFF;
  
  border-radius: 12px;
  border: 1px solid #ACACAC;

  color: #ACACAC;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: 'flex';
  justify-content: center;
}


.sexbuttonStyle2{
  width: 344px;
  height: 50px;
  flex-shrink: 0;

  background-color: #FFF;

  border-radius: 12px;
  border: 2px solid var(--Hoxy-Orange, #FF5C00);

  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;


  display: 'flex';
  justify-content: center;

}

.message_num_box{
  position: fixed;
  bottom: 12% ;
  left: 50%;
  transform: translateX(-50%);

  display: inline-flex;
  padding: 6px 11px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: var(--fills-tertiary, rgba(118, 118, 128, 0.12));

  color: var(--labels-secondary, rgba(60, 60, 67, 0.60));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -1.2px;
}

.bottom_box{
  position: fixed;
  bottom: 12% ;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  padding: 6px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: var(--labels-secondary, rgba(60, 60, 67, 0.60));
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  line-height: 22px; /* 146.667% */
  letter-spacing: -1.2px;
}

.tip_sentence{
  text-align: left;
  margin-left: 27px;

  color: #6E747D;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.36px;
}

.select_relationship {
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  width: 165px;
  height: 55px;
  margin-top: 5px;
  border-color: #f0f0f0;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #acacac;
  font-family: 'Pretendard';
  letter-spacing: -0.08rem;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  border: 1px solid #acacac;
}

.select_relationship:active{
  color: #000000;
  border: 2px solid #ff1500;
  background: #fff;
  border-color: #ff1500;
}

.shuffle_button2{
  color: var(--Hoxy-Orange, #FF5C00);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.75px;
  border : '0';
  margin-top: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Pretendard';
}

.gray_text {
  color: rgb(139, 149, 161);
  text-align: center;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.04px;
  margin-top: 100px;
}

.today_date{
  width: 100px;
  height: 25px;
  flex-shrink: 0;

  border-radius: 6px;
  background: #FBF5F5;

  color: var(--Hoxy-Orange, #FF5C00);
  text-align: center;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;

  
}

.today_question{
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 25px;
  background: #FBF5F5;

  color: var(--Hoxy-Orange, #FF5C00);
  text-align: center;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.6px;

  position: fixed;
  bottom: 67.5%;
  left: 47.5%;
  transform: translateX(-50%);
}


.bottom_linkbox{
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.bottom_linkbox_text{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 106%; /* 8.48px */
  letter-spacing: -0.4px;
}

.middle_line{
  width: 5px;
  height: 41px;
  flex-shrink: 0;

  stroke-width: 1.6px;
  stroke: #000;
}

.FriendBoxButton2{
  display: flex;
  color: #778694;
  text-align: center;
  font-family: Pretendard;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
}

/* Styles for the unchecked state */
input[type="checkbox"] {
  
  width: 15px;
  height: 15px;
  border: 2px solid #555; /* Border color for unchecked state */
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  accent-color: #EEEEEF;
}

input[type="checkbox"]:checked {
  accent-color: #FF5C00;
}

.footprint_1 {
  width: 105px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 15px;
  color: #909090;
  background: #F5F5F6;
  cursor: pointer;
  transition: color 0.3s, background 0.3s; /* Add transition for smooth effect */
}

.footprint_1:hover {
  color: #fff; /* Change to desired color on hover */
  background: #FF5C00; /* Change to desired background color on hover */
}

.footprint_2{
  width: 105px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 15px;
  color: #909090;
  background: #F5F5F6;
  cursor: pointer;
  transition: color 0.3s, background 0.3s; /* Add transition for smooth effect */
  margin-top: -22px;
  margin-left: 15px;
}

.footprint_2:hover {
  color: #fff; /* Change to desired color on hover */
  background: #FF5C00; /* Change to desired background color on hover */
}

.footprint_3{
  width: 105px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 15px;
  color: #909090;
  background: #F5F5F6;
  cursor: pointer;
  transition: color 0.3s, background 0.3s; /* Add transition for smooth effect */
  margin-left: 15px;
}

.footprint_3:hover {
  color: #fff; /* Change to desired color on hover */
  background: #FF5C00; /* Change to desired background color on hover */
}

.footprint_font_1{
  text-align: center;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.5px;
  margin-top: 17px;

}



.footprint_font_2{
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.7px;
}



.question_answer {
  overflow: auto;
  width: 200px;
  max-height: 100px;
  word-wrap: break-word;
  overflow-y: auto;
}


nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  
  display: flex;
  border: solid 2px;

}

nav > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EventBlock_font{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.68px;
}

.profile_insta{
  color: var(--Hoxy-Orange, #FF5C00);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-top: 10px;
}

.profile_school{
  color: #6E747D;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.9px;
  text-align: left;
  margin-top: 2px;
  width: 180px;
}

.profile_name{
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.3px;
  margin-top: 5px;
  text-align: left;
}

/* 네비바 하단 고정 밑 세로 길이 설정 */
.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff;
  border-radius: 12px;
}

/* nav태그 아래의 자식들을 수평정렬하기 위한 설정 */
nav {
  overflow: hidden;
  border-top: 2px solid grey;
}
/* nav태그 아래의 div태그들을 수평정렬 및 세로길이 설정 */
nav > div {
  /* 수평정렬, 5개의 button을 각각 20% width만큼 할당 */
  float: left;
  width: 25%;
  margin-top: 7px;
  text-align: center;
  /* 세로길이 설정 */
  height: 45px;
  line-height: 45px;
}

/* 하단 네비바의 메뉴를 눌렀을 때 색상 변경*/
.nav-item {
  color: grey;
}

/* 하단 네비바의 메뉴를 눌렀을 때 색상 변경*/
.active {
  color: #ef5c89;
}

.bottom_nav_font{
  color: #000;
  font-family: Pretendard;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.7px;
  margin-left: 0px;
  margin-top: -14px;
}